import { toastSuccess, toastWarning, toastPop } from '@components/Toasts.svelte';

import { marked } from 'marked';
import DOMPurify from 'dompurify';

// Utility function to render a snippet and return the resulting HTML string
export function renderComponentToHTML(snippet, ...args) {
  const wrappedArgs = args;

  // Create a temporary parent node
  const parentNode = document.createElement('div');
  // Create an empty text node to act as the insertion anchor
  const childNode = document.createTextNode('');  // Text node as the anchor

  // Append the childNode (anchor) to the parentNode
  parentNode.appendChild(childNode);

  // Call the snippet with the childNode as the anchor
  snippet?.(childNode, ...wrappedArgs);

  // Return the innerHTML of parentNode, which now contains only the snippet content
  return parentNode.innerHTML;
}

// Utility function to render a snippet and return the resulting HTML string
export function renderSnippetToHTML(snippet, ...args) {
  const wrappedArgs = args.map(arg => () => arg);

  // Create a temporary parent node
  const parentNode = document.createElement('div');
  // Create an empty text node to act as the insertion anchor
  const childNode = document.createTextNode('');

  // Append the childNode (anchor) to the parentNode
  parentNode.appendChild(childNode);

  // Call the snippet with the childNode as the anchor
  snippet?.(childNode, ...wrappedArgs);

  // Return the innerHTML of parentNode, which now contains only the snippet content
  return parentNode.innerHTML;
}

export function extract(map, prefix) {
  let extracted = {};
  const prefixLength = prefix.length;

  Object.keys(map).forEach(key => {
    //console.log('key: ', key, 'value: ', map[key]);
    if (key.startsWith(prefix)) {
      extracted[key.slice(prefixLength)] = map[key];
      delete map[key];
    }

  });

  return extracted;
}

export function escapeHTML(unsafe) {
  return unsafe.replace(
    /[\u0000-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u00FF]/g,
    c => '&#' + ('000' + c.charCodeAt(0)).slice(-4) + ';'
  )
}

/**
 * Converts a Markdown message to sanitized HTML.
 * Uses GitHub-flavored Markdown with line breaks enabled.
 *
 * @param {string} message - The Markdown string to convert.
 * @returns {string} - The sanitized HTML safe for rendering.
 */
export function renderMarkdown(message) {
  // Convert the Markdown into HTML using GitHub-flavored Markdown and enabling line breaks.
  const generatedHTML = marked(message, {
    gfm: true,
    breaks: true
  });

  // Sanitize the generated HTML by allowing only a limited set of tags and attributes.
  const safeHTML = DOMPurify.sanitize(generatedHTML, {
    ALLOWED_TAGS: [
      'b', 'i', 'em', 'strong', 'u',               // text formatting
      'p', 'br',                                   // paragraphs and line breaks
      'ul', 'ol', 'li',                            // lists
      'blockquote',                                // block quotes
      'code', 'pre',                               // inline and block code
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6',          // headings
      'a',                                         // links
      'table', 'thead', 'tbody', 'tr', 'th', 'td', // tables

    ],
    ALLOWED_ATTR: ['href']
  });

  // If the sanitized HTML is empty or only whitespace, return a placeholder.
  if (safeHTML.trim() == '<p></p>') {
    return '<p><i>Message content removed for safety.</i></p>';
  }

  return safeHTML;
}

export function loadRecaptcha(siteKey, nonce) {
    return new Promise((resolve, reject) => {
        if (window.grecaptcha) {
            // reCAPTCHA already loaded
            resolve(window.grecaptcha);
            return;
        }

        const script = document.createElement('script');
        script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${siteKey}`;
        script.async = true;
        script.defer = true;
        if (nonce) {
            script.setAttribute('nonce', nonce);
        }
        script.onload = () => {
            window.grecaptcha.ready(() => {
                console.log('reCaptcha ready');
                resolve(window.grecaptcha);
            });
        };
        script.onerror = () => {
            reject(new Error('Failed to load reCaptcha'));
        };

        document.body.appendChild(script);
    });
}

export async function download(url) {
  console.log('download', url);

  let errorMsg;
  let successMsg;

  try {
    // Show the success toast
    successMsg = toastSuccess('Download will begin shortly');
    if (errorMsg) toastPop(errorMsg);

    // Fetch the file
    const response = await fetch(url);

    if (!response.ok) {
      console.error(response);
      throw new Error(`Download failed: ${response.status} ${response.statusText}`);
    }

    // Get the filename from the Content-Disposition header
    const disposition = response.headers.get('Content-Disposition');
    const filename_header = response.headers.get('Filename');
    let filename = 'downloaded-file';
    if (filename_header) {
      filename = decodeURI(filename_header);
    } else if (disposition && disposition.includes('attachment')) {
      const match = disposition.match(/filename="?([^"]+)"?/);
      if (match && match[1]) {
        filename = match[1];
      }
    }
    console.log('Download: ', filename, 'disp: ', disposition);

    // Convert the response to a Blob
    const blob = await response.blob();

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);

    console.log('Download finished');
    toastPop(successMsg);

  } catch (error) {
    console.error(error);
    toastPop(successMsg);
    errorMsg = toastWarning(`${error.message}`);
  }
}

export function formatFileSize(bytes, decimalPoints = 2, k = 1000) {
  if (bytes === 0) return '0 Bytes';

  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoints));

  return `${formattedSize} ${sizes[i]}`;
}
